<template>
  <div class="my">
    <div style="height: 5.625rem"></div>
    <div class="px-315 py-45">
      <!-- 头部 -->
      <div class="bgbox flex align-center">
        <!-- <img
          :src="info.avatar"
          width="4.375rem"
          height="4.375rem"
          class="circle"
          alt=""
        /> -->
        <el-image
          style="width: 4.375rem; height: 4.375rem"
          :src="info.avatar"
          class="circle"
          fit="cover"
        ></el-image>
        <div class="ml-10">
          <div class="flex align-center font-26 font-weight-bolder text-white">
            {{ info.username }}
            <div class="ml-20" v-if="user_level_id == 2">
              <!-- <img
                src="@/assets/img/icon_myvip.png"
                width="4.875rem"
                height="1.625rem"
                alt=""
              /> -->
              <el-image
                style="width: 4.875rem; height: 1.625rem"
                :src="require('@/assets/img/icon_myvip.png')"
                class="circle"
                fit="cover"
              ></el-image>
            </div>
          </div>
          <div class="mt-15 flex align-center">
            <div
              class="font-20 mr-15 text-white flex align-center cursorp"
              @click="gomyfans(1)"
            >
              <!-- <img
                src="@/assets/img/icon_top1.png"
                width="2.1875rem"
                height="2.1875rem"
                alt=""
              /> -->
              <el-image
                style="width: 2.1875rem; height: 2.1875rem"
                :src="require('@/assets/img/icon_top1.png')"
                fit="cover"
              ></el-image>
              {{ info.fans_nums }}<span class="font-14">粉丝</span>
            </div>
            <div
              class="font-20 mr-15 text-white flex align-center cursorp"
              @click="gomyfans(2)"
            >
              <!-- <img
                src="@/assets/img/icon_top2.png"
                width="2.1875rem"
                height="2.1875rem"
                alt=""
              /> -->
              <el-image
                style="width: 2.1875rem; height: 2.1875rem"
                :src="require('@/assets/img/icon_top2.png')"
                fit="cover"
              ></el-image>
              {{ info.fabulous_nums }}<span class="font-14">获赞</span>
            </div>
            <div
              class="font-20 text-white flex align-center cursorp"
              @click="gomyfans(3)"
            >
              <!-- <img
                src="@/assets/img/icon_top3.png"
                width="2.1875rem"
                height="2.1875rem"
                alt=""
              /> -->
              <el-image
                style="width: 2.1875rem; height: 2.1875rem"
                :src="require('@/assets/img/icon_top3.png')"
                fit="cover"
              ></el-image>
              {{ info.follow_nums }} <span class="font-14">关注</span>
            </div>
          </div>
        </div>
      </div>
      <!-- 中间部分 -->
      <div class="flex mt-20">
        <div class="lable">
          <li
            v-for="(item, index) in lableList"
            :key="index"
            class="lable_li flex align-center mb-25 font-14 cursorp"
            :class="type == 'my' && mynavid == item.id ? 'text-E7524C' : 'text-666666'"
            @click="gomytype('my', item.id, item.path)"
          >
            <!-- <img
              :src="item.img"
              width="1.625rem"
              height="1.625rem"
              class="mr-10"
              alt=""
            /> -->
            <el-image
              class="mr-10"
              style="width: 1.625rem; height: 1.625rem"
              :src="item.img"
              fit="cover"
            ></el-image>
            {{ item.title }} <span v-if="item.id == 10">({{ info.surplus_nums }})</span>
          </li>
        </div>
        <div class="x-1 ml-20">
          <router-view />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import req from "../../utils/req";
export default {
  data() {
    return {
      mynavid: "",
      type: "",
      user_level_id: localStorage.getItem("user_level_id"),
      lableList: [
        {
          id: 1,
          img: require("@/assets/img/icon_my1.png"),
          title: "我的钱包",
          path: "/myPurse",
        },
        {
          id: 2,
          img: require("@/assets/img/icon_my2.png"),
          title: "观看历史",
          path: "/watchHistory",
        },
        {
          id: 3,
          img: require("@/assets/img/icon_my3.png"),
          title: "我的收藏",
          path: "/myFavorite",
        },
        {
          id: 4,
          img: require("@/assets/img/icon_my4.png"),
          title: "我的推广",
          path: "/Mypromotion",
        },
        {
          id: 5,
          img: require("@/assets/img/icon_my5.png"),
          title: "我的分享码",
          path: "/inviteFriends",
        },
        // {
        //   id: 10,
        //   img: require("@/assets/img/icon_my10.png"),
        //   title: "我的抽奖券",
        //   path: "/choujiangquan",
        // },
        {
          id: 11,
          img: require("@/assets/img/icon_my11.png"),
          title: "套餐明细",
          path: "/mytaocan",
        },
        {
          id: 6,
          img: require("@/assets/img/icon_my6.png"),
          title: "申请成为推广合伙人",
          path: "/ApplicationPartnership",
        },
        {
          id: 7,
          img: require("@/assets/img/icon_my7.png"),
          title: "我的客服",
          path: "/mykf",
        },
        {
          id: 8,
          img: require("@/assets/img/icon_my8.png"),
          title: "关于我们",
          path: "/AboutUs",
        },
        {
          id: 9,
          img: require("@/assets/img/icon_my9.png"),
          title: "设置",
          path: "/set",
        },
      ],
      info: "",
    };
  },
  watch: {
    // 被侦听的变量count
    $route: {
      handler() {
        let mynavid = this.$route.query.mynavid;
        let type = this.$route.query.type;
        if (mynavid) {
          this.mynavid = mynavid;
        } else {
          this.mynavid = "";
        }
        if (type) {
          this.type = type;
        } else {
          this.type = "";
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getdata();
    let mynavid = this.$route.query.mynavid;
    let type = this.$route.query.type;
    if (mynavid) {
      this.mynavid = mynavid;
    } else {
      this.mynavid = "";
    }
    if (type) {
      this.type = type;
    } else {
      this.type = "";
    }
  },
  methods: {
    gomyfans(id) {
      this.$router.push({
        path: "/myfans",
        query: {type: "myfans", mytype: id },
      });
    },
    gomytype(type, id, path) {
      this.$router.push({
        path: path,
        query: { type, mynavid: id },
      });
    },
    getdata() {
      let that = this;
      req
        .post("center/my", {})
        .then((res) => {
          if (res.code != 200) {
            // that.$message.error(res.msg);
            return;
          }
          that.info = res.data;
        })
        .catch((err) => {
          console.log(err);
          // that.$message.error(err.msg);
        });
    },
  },
};
</script>
<style lang="scss">
.el-cascader {
  width: 25rem;
}
</style>
<style scoped lang="scss">
.my {
  .lable {
    padding: 2.125rem 2rem 2.5625rem 1.625rem;
    border: 0.0625rem solid #eeeeee;
    flex-shrink: 0;
  }
  .bgbox {
    // width: 100%;
    height: 10.375rem;
    background-image: url("../../assets/img/mybg.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 2.9375rem 3.25rem 1.5rem;
  }
}
</style>
